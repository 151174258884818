<div class="topbar">

  <span *ngIf="showDashboard" style="padding-left: 5px;">

    <a class="mr-1 org" nz-button nz-dropdown [nzDropdownMenu]="menu4">
      Organization: {{ userData.orgName }}
      <i nz-icon nzType="down"></i>
    </a>
    <nz-dropdown-menu #menu4="nzDropdownMenu">
      <ul nz-menu>
        <span *ngIf="module == 'Messages'|| module == 'SIC' || module == 'Dashboard' || module == 'DocsVault'">
          <li (click)="orgChange({ orgName: 'All', _id: 'All' })" nz-menu-item>All</li>
          <li *ngFor="let org of organizations" nz-menu-item (click)="orgChange(org)">
            {{ org.orgName }}
          </li>
        </span>

        <span
          *ngIf="module == 'Checklist' || module ==  'My Drive' || module == 'Products' || module ==  'BD Collateral' ||module ==  'Chat' || module ==  'Reports' || module == 'Calendar'">
          <li nzDisabled (click)="orgChange({ orgName: 'All', _id: 'All' })" nz-menu-item>All</li>
          <li nzDisabled *ngFor="let org of organizations" nz-menu-item (click)="orgChange(org)">
            {{ org.orgName }}
          </li>
        </span>

      </ul>
    </nz-dropdown-menu>

    <!-- admin -->
    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Organization'">{{ header | titlecase }}</a> -->
    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Product'">{{ header | titlecase }}</a> -->
    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Users'">{{ header | titlecase }}</a> -->

    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Dashboard'"><i
        class="fe fe-check-airplay font-size-16 mr-2"></i>{{ header | titlecase }}</a> -->
    <a class="font-size-18 text-gray" *ngIf="header == 'Checklist'"><i
        class="fe fe-check-square font-size-16 mr-2"></i>TrackTask</a>
    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Products'"><i class="fe fe-fe-list font-size-16 mr-2"></i>{{
      header | titlecase }}</a> -->
    <a class="font-size-18 text-gray" *ngIf="header == 'BD Collateral'"><i
        class="fe fe-radio font-size-16 mr-2"></i>PartnerPod</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Chat'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Calendar'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Intranet'"><i class="fe fe-server font-size-16 mr-2"></i>{{
      header }}</a>

    <!-- <span *ngIf="
        header == 'Sent' ||
        header == 'Inbox' ||
        header == 'Compose' ||
        header == 'Messages' ||
        header == 'Drafts' ||
        header == 'Message Details'||
        header == 'All mails'
      ">
      <a class="font-size-18 text-primary" nz-dropdown [nzDropdownMenu]="menu">
        {{ header | titlecase }}
        <i nz-icon nzType="down"></i>
      </a>
      <a class="font-size-18 text-gray p-2 links" [routerLink]="['/user', 'request', 'compose']"
        *ngIf="header !== 'Compose'">Compose</a>
      <a class="font-size-18 text-gray p-2 links" [routerLink]="['/user', 'request', 'mail-inbox']"
        *ngIf="header !== 'Inbox'">Inbox</a>
      <a [routerLink]="['/user', 'request', 'request-log']" class="font-size-18 text-gray p-2 links"
        *ngIf="header !== 'Sent'">Sent</a>
      <a [routerLink]="['/user', 'request', 'drafts']" class="font-size-18 text-gray p-2 links"
        *ngIf="header !== 'Drafts'">Drafts</a>
      <a [routerLink]="['/user', 'request', 'all-mails']" class="font-size-18 text-gray p-2 links"
        *ngIf="header !== 'AllMail'">All Mails</a>
      </span> -->

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li [routerLink]="['/user', 'request', 'compose']" nz-menu-item>Compose</li>
        <li [routerLink]="['/user', 'request', 'mail-inbox']" nz-menu-item>Inbox</li>
        <li [routerLink]="['/user', 'request', 'request-log']" nz-menu-item>Sent</li>
        <li [routerLink]="['/user', 'request', 'drafts']" nz-menu-item>Draft</li>
        <li [routerLink]="['/user', 'request', 'all-mails']" nz-menu-item>All Mails</li>
      </ul>
    </nz-dropdown-menu>

    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Generate Request'">{{header | titlecase }}</a> -->
    <!-- <a class="font-size-18 text-gray" *ngIf="header == 'Message Details'">{{header | titlecase }}</a> -->

    <a class="font-size-18 text-gray" *ngIf="header == 'SIC'" (click)="goToSicDash()">ChangeCenter</a>

    <!-- checklist breadcrumbs -->
    <span *ngIf="header == 'Checklist Details'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToChecklistDashboard()">Checklist <span> / </span></a>
        <a>Checklist Details</a>
      </div>
    </span>

    <span *ngIf="header == 'Create a Checklist'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToChecklistDashboard()">TrackTask<span> / </span></a>
        <a>Create a Checklist</a>
      </div>
    </span>
    <span *ngIf="header == 'Checklist Tasks Dashboard'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToChecklistDashboard()">TrackTask <span> / </span></a>
        <a>Tasks</a>
      </div>
    </span>
    <span *ngIf="header == 'Task Details'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToChecklistDashboard()">TrackTask <span> / </span></a>
        <a>Task Details</a>
      </div>
    </span>

    <span *ngIf="header == 'Create a Checklist Tasks'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToChecklistDashboard()">TrackTask <span> / </span></a>
        <a>Create a Task</a>
      </div>
    </span>

    <!-- Product module breadcrumbs -->
    <span *ngIf="header == 'Product Documents'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToProductModule()">Products <span> / </span></a>
        <a>{{ breadcrumbs[0] }}</a>
      </div>
    </span>

    <!-- Reports -->
    <a class="font-size-18 text-gray" *ngIf="header == 'Reports'">{{ header }}</a>
    <span *ngIf="header == 'Report Message Details'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <span class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goBackToReportDashboard()">Reports<span> / </span></a>
        <a>Message Details</a>
      </span>
    </span>

    <!-- Requests -->
    <a class="font-size-18 text-gray" *ngIf="header == 'Inbox'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Message Details'">
      <i class="fe fe-book font-size-16 mr-2"></i>{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Compose'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Sent'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Drafts'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'All Mails'">{{ header }}</a>
    <a class="font-size-18 text-gray" *ngIf="header == 'Trash'">{{ header }}</a>

    <!-- bd collateral breadcrumbs -->
    <span *ngIf="header == 'BD Collateral Documents'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <span class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToBDCollateralModule()">PartnerPod<span> / </span></a>
        <a>{{ breadcrumbs[0]?.typeName }}</a>
      </span>
    </span>
    <span *ngIf="header == 'collateral Product Catalogue'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <span class="text-nowrap d-inline-block font-size-18 text-dark">
        <a (click)="goToBDCollateralModule()">PartnerPod<span> / </span></a>
        <a>Product Catalogue of {{ subscriberName }}</a>
      </span>
    </span>

    <!-- mydrive breadcrumbs -->
    <span *ngIf="header == 'My Drive'">
      <i class="fe fe-book font-size-16 "></i>
      <!-- <span class="text-nowrap d-inline-block font-size-18 text-dark">
        <a *ngFor="let crumbs of breadcrumbs" class="font-size-18 text-blue">
          <a *ngIf="crumbs.path == 'mydrive'" [routerLink]="['/user', 'mydrive']">
            {{ crumbs.name }} <span> / </span>
          </a>
          <a *ngIf="crumbs.path == 'folder'" [routerLink]="['/user', 'mydrive', crumbs.path]"
            [queryParams]="{ folderId: crumbs.id }">
            {{ crumbs.name }} <span *ngIf="!router.url.includes(crumbs.id)"> / </span>
          </a>
          <a *ngIf="crumbs.path == 'doc'" [routerLink]="['/user', 'mydrive', crumbs.path]"
            [queryParams]="{ docId: crumbs.id }">
            {{ crumbs.name }} <span *ngIf="!router.url.includes(crumbs.id)"> / </span>
          </a>
        </a>
      </span> -->




      <span *ngIf="!crumbsDot">
        <a *ngFor="let crumbs of breadcrumbs" class="font-size-18 text-blue">
          <a *ngIf="crumbs.path == 'mydrive'" [routerLink]="['/user', 'mydrive']">
            <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
            <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
            <span class="p-2"> / </span>
          </a>
          <a *ngIf="crumbs.path == 'folder'" [routerLink]="['/user', 'mydrive', crumbs.path]"
            [queryParams]="{ folderId: crumbs.id }">
            <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
            <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
            <span class="p-2" *ngIf="!router.url.includes(crumbs.id)"> / </span>
          </a>
          <a *ngIf="crumbs.path == 'doc'" [routerLink]="['/user', 'mydrive', crumbs.path]"
            [queryParams]="{ docId: crumbs.id }">
            <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
            <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
            <span class="p-2" *ngIf="!router.url.includes(crumbs.id)"> / </span>
          </a>
        </a>
      </span>
      <span *ngIf="crumbsDot">
        <a class="font-size-18 text-blue">
          <a *ngIf="breadcrumbs[0].path == 'mydrive'" [routerLink]="['/user', 'mydrive', ]">
            <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
            <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
            <span class="p-2"> / </span>
          </a>
          <a *ngIf="breadcrumbs[0].path == 'folder'" [routerLink]="['/user', 'mydrive', breadcrumbs[0].path]"
            [queryParams]="{ folderId: breadcrumbs[0].id }">
            <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
            <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
            <span class="p-2"> / </span>
          </a>
          <a *ngIf="breadcrumbs[0].path == 'doc'" [routerLink]="['/user', 'mydrive', breadcrumbs[0].path]"
            [queryParams]="{ docId: breadcrumbs[0].id }">
            <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
            <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
          </a>
        </a>

        <a class="font-size-18 text-blue">

          <a *ngIf="breadcrumbs[1]?.path == 'folder'" [routerLink]="['/user', 'mydrive', breadcrumbs[1]?.path]"
            [queryParams]="{ folderId: breadcrumbs[1]?.id }">
            <span *ngIf="breadcrumbs[1]?.name.length >= 20">{{ breadcrumbs[1]?.name.substring(0,12)+"..." }}</span>
            <span *ngIf="breadcrumbs[1]?.name.length < 20">{{ breadcrumbs[1]?.name }}</span>
          </a>
          <a *ngIf="breadcrumbs[1]?.path == 'doc'" [routerLink]="['/user', 'mydrive', breadcrumbs[1]?.path]"
            [queryParams]="{ docId: breadcrumbs[1]?.id }">
            <span *ngIf="breadcrumbs[1]?.name.length >= 20">{{ breadcrumbs[1]?.name.substring(0,12)+"..." }}</span>
            <span *ngIf="breadcrumbs[1]?.name.length < 20">{{ breadcrumbs[1]?.name }}</span>
          </a>
        </a>
        <span *ngIf="breadcrumbs[crumbsLength-1]?.id != breadcrumbs[1]?.id">
          <span *ngIf="crumbsLength == 3"> / </span>
          <span *ngIf="crumbsLength != 3">...</span>
          <a class="font-size-18 text-blue">

            <a *ngIf="breadcrumbs[crumbsLength-1]?.path == 'folder'"
              [routerLink]="['/user', 'mydrive', breadcrumbs[crumbsLength-1]?.path]"
              [queryParams]="{ folderId: breadcrumbs[crumbsLength-1]?.id }">
              <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length >= 20">{{
                breadcrumbs[crumbsLength-1]?.name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length < 20">{{ breadcrumbs[crumbsLength-1]?.name }}</span>
            </a>
            <a *ngIf="breadcrumbs[crumbsLength-1]?.path == 'doc'"
              [routerLink]="['/user', 'mydrive', breadcrumbs[crumbsLength-1]?.path]"
              [queryParams]="{ docId: breadcrumbs[crumbsLength-1]?.id }">
              <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length >= 20">{{
                breadcrumbs[crumbsLength-1]?.name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length < 20">{{ breadcrumbs[crumbsLength-1]?.name }}</span>
            </a>
          </a>
        </span>
      </span>


    </span>

    <!-- dl breadcrumbs -->
    <span *ngIf="header == 'DocsVault'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-16 text-dark">

        <span *ngIf="!crumbsDot">
          <a *ngFor="let crumbs of breadcrumbs" class="font-size-18 text-blue">
            <a *ngIf="crumbs.path == 'dash'" [routerLink]="['/user', 'doc_lib', crumbs.path]">
              <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
              <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
              <span class="p-2"> / </span>
            </a>
            <a *ngIf="crumbs.path == 'folder'" [routerLink]="['/user', 'doc_lib', crumbs.path]"
              [queryParams]="{ folderId: crumbs.id }">
              <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
              <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
              <span class="p-2" *ngIf="!router.url.includes(crumbs.id)"> / </span>
            </a>
            <a *ngIf="crumbs.path == 'doc'" [routerLink]="['/user', 'doc_lib', crumbs.path]"
              [queryParams]="{ docId: crumbs.id }">
              <span *ngIf="crumbs.name.length >= 20">{{ crumbs.name.substring(0,12)+"..." }}</span>
              <span *ngIf="crumbs.name.length < 20">{{ crumbs.name }}</span>
              <span class="p-2" *ngIf="!router.url.includes(crumbs.id)"> / </span>
            </a>
          </a>
        </span>
        <span *ngIf="crumbsDot">
          <a class="font-size-18 text-blue">
            <a *ngIf="breadcrumbs[0].path == 'dash'" [routerLink]="['/user', 'doc_lib', breadcrumbs[0].path]">
              <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
              <span class="p-2"> / </span>
            </a>
            <a *ngIf="breadcrumbs[0].path == 'folder'" [routerLink]="['/user', 'doc_lib', breadcrumbs[0].path]"
              [queryParams]="{ folderId: breadcrumbs[0].id }">
              <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
              <span class="p-2"> / </span>
            </a>
            <a *ngIf="breadcrumbs[0].path == 'doc'" [routerLink]="['/user', 'doc_lib', breadcrumbs[0].path]"
              [queryParams]="{ docId: breadcrumbs[0].id }">
              <span *ngIf="breadcrumbs[0].name.length >= 20">{{ breadcrumbs[0].name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[0].name.length < 20">{{ breadcrumbs[0].name }}</span>
            </a>
          </a>

          <a class="font-size-18 text-blue">
            <a *ngIf="breadcrumbs[1]?.path == 'folder'" [routerLink]="['/user', 'doc_lib', breadcrumbs[1]?.path]"
              [queryParams]="{ folderId: breadcrumbs[1]?.id }">
              <span *ngIf="breadcrumbs[1]?.name.length >= 20">{{ breadcrumbs[1]?.name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[1]?.name.length < 20">{{ breadcrumbs[1]?.name }}</span>
            </a>
            <a *ngIf="breadcrumbs[1]?.path == 'doc'" [routerLink]="['/user', 'doc_lib', breadcrumbs[1]?.path]"
              [queryParams]="{ docId: breadcrumbs[1]?.id }">
              <span *ngIf="breadcrumbs[1]?.name.length >= 20">{{ breadcrumbs[1]?.name.substring(0,12)+"..." }}</span>
              <span *ngIf="breadcrumbs[1]?.name.length < 20">{{ breadcrumbs[1]?.name }}</span>
            </a>
          </a>
          <span *ngIf="breadcrumbs[crumbsLength-1]?.id != breadcrumbs[1]?.id">
            <span *ngIf="crumbsLength == 3"> / </span>
            <span *ngIf="crumbsLength != 3">...</span>
            <a class="font-size-18 text-blue">
              <a *ngIf="breadcrumbs[crumbsLength-1]?.path == 'folder'"
                [routerLink]="['/user', 'doc_lib', breadcrumbs[crumbsLength-1]?.path]"
                [queryParams]="{ folderId: breadcrumbs[crumbsLength-1]?.id }">
                <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length >= 20">{{
                  breadcrumbs[crumbsLength-1]?.name.substring(0,12)+"..." }}</span>
                <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length < 20">{{ breadcrumbs[crumbsLength-1]?.name
                  }}</span>
              </a>
              <a *ngIf="breadcrumbs[crumbsLength-1]?.path == 'doc'"
                [routerLink]="['/user', 'doc_lib', breadcrumbs[crumbsLength-1]?.path]"
                [queryParams]="{ docId: breadcrumbs[crumbsLength-1]?.id }">
                <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length >= 20">{{
                  breadcrumbs[crumbsLength-1]?.name.substring(0,12)+"..." }}</span>
                <span *ngIf="breadcrumbs[crumbsLength-1]?.name.length < 20">{{ breadcrumbs[crumbsLength-1]?.name
                  }}</span>
              </a>
            </a>
          </span>
        </span>
      </div>
    </span>

    <span *ngIf="header == 'Compliance'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance</a>
      </div>
    </span>

    <span *ngIf="header == 'Compliance View'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance <span> / </span></a>
        <a>Document Details</a>
      </div>
    </span>

    <span *ngIf="header == 'Compliance Add'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance <span> / </span></a>
        <a>Add or Replace Document</a>
      </div>
    </span>

    <span *ngIf="header == 'Compliance Archive'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance <span> / </span></a>
        <a>Archived Documents</a>
      </div>
    </span>

    <span *ngIf="header == 'Compliance Awaiting'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance <span> / </span></a>
        <a>Awaiting Documents</a>
      </div>
    </span>

    <span *ngIf="header == 'Compliance Edit'">
      <i class="fe fe-book font-size-16 mr-2"></i>
      <div class="text-nowrap d-inline-block font-size-18">
        <a (click)="goToComplianceDashboard()">Compliance <span> / </span></a>
        <a>Edit Document</a>
      </div>
    </span>

    <!-- <vb-topbar-fav-pages></vb-topbar-fav-pages> -->
    <!-- <app-header [data]="{'title':'Forward Message'}"></app-header> -->
    <!-- <app-myheader [item]="currentItem"></app-myheader> -->
  </span>


  <div class="mr-4 d-none d-md-block">
    <!-- <vb-topbar-issues-history></vb-topbar-issues-history> -->
  </div>
  <div class="mr-auto d-xl-block d-none">
    <!-- <vb-topbar-project-management></vb-topbar-project-management> -->
  </div>


  <!-- <div class="ml-2 mr-4 d-none d-md-block">
    <form class="example-form">
      <mat-form-field class="example-full-width mat-width">
        <mat-label>Seach anything...</mat-label>
        <input type="text" placeholder="Enter atlest 3 letters" matInput (input)="onInputChange($event.target.value)"
          [formControl]="myControl" [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete">
          <a *ngFor="let result of docsVaultOwnSearchResults" [routerLink]="['/user/doc_lib/doc/']"
            [queryParams]="{ docId: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">DocsVault</span>
            </mat-option>
          </a>
          <a *ngFor="let result of docsVaultaccessibleSearchResults" [routerLink]="['/user/doc_lib/doc/']"
            [queryParams]="{ docId: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">DocsVault</span>
            </mat-option>
          </a>
          <a *ngFor="let result of docsVaultFoldersSearchResults" [routerLink]="['/user/doc_lib/folder/']"
            [queryParams]="{ folderId: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="folder" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">DocsVault</span>
            </mat-option>
          </a>

          <a *ngFor="let result of checklistSearchResults" [routerLink]="['/user/checklist/task-dashboard/']"
            [queryParams]="{ id: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="carry-out" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">TrackTask</span>
            </mat-option>
          </a>
          <a *ngFor="let result of taskSearchResults" [routerLink]="['/user/checklist/task-details/']"
            [queryParams]="{ id: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="profile" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.requirementTitle }}</span>
              <span class="module-name">Task</span>
            </mat-option>
          </a>

          <a *ngFor="let result of driveSearchResults" [routerLink]="['/user/mydrive/folder/']"
            [queryParams]="{ folderId: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="folder" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">MyDrive</span>
            </mat-option>
          </a>
          <a *ngFor="let result of driveDocumentsSearchResults" (click)="openFile(result.path)">
            <mat-option>
              <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.name }}</span>
              <span class="module-name">MyDrive</span>
            </mat-option>
          </a>

          <a *ngFor="let result of productsSearchResults" [routerLink]="['/user/product-module/product-documents/']"
            [queryParams]="{ id: result.productId._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.productId.productName }}</span>
              <span class="module-name">Products</span>
            </mat-option>
          </a>

          <a *ngFor="let result of changeCentersSearchResults" [routerLink]="['/user/sic/sic-details/']"
            [queryParams]="{ CustomerSicId: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.globalSicId.nameAndRefrenceNumberOfModulesWithChanges }}</span>
              <span class="module-name">ChangeCenter</span>
            </mat-option>
          </a>

          <a *ngFor="let result of inboxSearchResults" [routerLink]="['/user/request/request-info/']"
            [queryParams]="{ request_id: result.requestId._id, mail_id: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="mail" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.requestId.subject }}</span>
              <span class="module-name">Inbox</span>
            </mat-option>
          </a>
          <a *ngFor="let result of sentboxSearchResults" [routerLink]="['/user/request/request-info/']"
            [queryParams]="{ request_id: result.requestId._id, mail_id: result._id }" target="_blank">
            <mat-option>
              <i class="mr-1" nz-icon nzType="mail" nzTheme="twotone"></i>
              <span class="ellipsis-span">{{ result.requestId.subject }}</span>
              <span class="module-name">Sentbox</span>
            </mat-option>
          </a>

        </mat-autocomplete>
      </mat-form-field>
    </form>
  </div> -->


  <div class="mr-1 d-none d-sm-block pt-1" (click)="globalSearch()" nzTooltipTitle="Global Search"
    nzTooltipPlacement="top" nz-tooltip>
    <nz-badge class="badge">
      <i class="icon fe fe-search"></i>
    </nz-badge>
  </div>

  <div class="mr-1 d-none d-sm-block">
    <span *ngIf="emailButtons">

      <button nz-button (click)="showSignModal()" class="text-nowrap mt-1 mr-2" nzType="default">
        <span class="btn-addon">
          <i class="mr-1" nz-icon nzType="highlight" nzTheme="outline"></i>
        </span>
        Email Signature
      </button>

      <button nz-button (click)="linkAccount()" nzType="default">
        <span class="btn-addon">
          <i class="mr-1" nz-icon nzType="link" nzTheme="outline"></i>
        </span>
        Link Account
      </button>
    </span>
  </div>

  <!-- <div class="mr-1 d-none d-sm-block pt-1" (click)="goToChat()" nzTooltipTitle="Chat" nzTooltipPlacement="top"
    nz-tooltip>

    <nz-badge nzDot [nzShowDot]="this.chatService.unreadNotificationsExist" class="badge">
      <i class="icon fe fe-message-square"></i>
    </nz-badge>
    <span *ngIf="chatService.chatUnreadCount !=0" class="badge-light">({{chatService.chatUnreadCount}})</span>

  </div> -->

  <!-- <div class="mr-1 d-none d-sm-block pt-1" (click)="copyMeetLink()" nzTooltipTitle="Meet" nzTooltipPlacement="top"
    nz-tooltip>
    <nz-badge class="badge">
      <i class="icon fe fe-video"></i>
    </nz-badge>
  </div> -->

  <div class="mr-1 d-none d-sm-block pt-1" (click)="goToCalendar()" nzTooltipTitle="Calendar" nzTooltipPlacement="top"
    nz-tooltip>
    <!-- <vb-topbar-search></vb-topbar-search> -->
    <!-- <vb-topbar-cart></vb-topbar-cart> -->
    <nz-badge class="badge">
      <i class="icon fe fe-calendar"></i>
    </nz-badge>
  </div>
  <!-- <div style="display: none;">
    <vb-topbar-actions></vb-topbar-actions>
  </div> -->

  <div class="mr-1 d-none d-sm-block pt-1">
    <nz-badge class="badge">
      <i class="icon fe fe-settings" nz-dropdown [nzDropdownMenu]="menu"></i>
    </nz-badge>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu>
        <li nz-menu-item (click)="togglePreselectedThemes()">Layouts</li>
        <li nz-menu-item (click)="setTheme(settings.theme === 'default' ? 'dark' : 'default')">Dark/Light
        </li>
      </ul>
    </nz-dropdown-menu>
  </div>

  <!-- <div class="mr-1 d-none d-sm-block pt-1">
    <nz-badge class="badge">
      <i class="icon fe fe-help-circle">
        <vb-support-chat></vb-support-chat>
      </i>
    </nz-badge>
  </div> -->

  <div class="mr-1 d-none d-sm-block">
    <!-- <vb-topbar-language-switcher></vb-topbar-language-switcher> -->
    <!-- <vb-topbar-search></vb-topbar-search> -->
  </div>
  <div>
    <vb-topbar-user-menu></vb-topbar-user-menu>
  </div>

  <nz-modal [(nzVisible)]="isSignVisible" nzTitle="Email Signature" (nzOnCancel)="handleSignCancel()"
    (nzOnOk)="handleSignOk()">
    <ng-container *nzModalContent>
      <p>Enter text for your signature or paste HTML code of your signature from an external source (e.g. <a
          style="color: hsl(206,100%,40%);"
          (click)="goToLink('https://www.hubspot.com/email-signature-generator')">email-signature-generator</a>)</p>
      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please write something here!">
            <textarea [(ngModel)]="existingSign" formControlName="signature" nz-input rows="5"
              placeholder="Write your signature"></textarea>
          </nz-form-control>
        </nz-form-item>
      </form>
    </ng-container>
  </nz-modal>
</div>

<nz-modal [(nzVisible)]="isVisible" nzTitle="Link Account" [nzWidth]="500" (nzOnCancel)="handleCancel()"
  (nzOnOk)="link()">
  <ng-container *nzModalContent>
    <nz-spin [nzSize]="'large'" nzTip="Loading..." [nzSpinning]="isSpinning">
      <form [formGroup]="emailData">
        <div class="row">
          <div class="col-md-12">
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Username</mat-label>
              <input matInput type="email" formControlName="user" readonly />
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="password" />
            </mat-form-field>
            <br>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>Server</mat-label>
              <input matInput type="text" formControlName="host" />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 100%">
              <mat-label>App Password</mat-label>
              <input matInput type="text" formControlName="appPassword" placeholder="Only for gmail account" />
            </mat-form-field>
          </div>
        </div>
      </form>
    </nz-spin>
    <span [ngClass]="alert ? 'green' : 'red'">{{alertMsg}}</span>
  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancel()">Cancel</button>
    <button nz-button nzType="primary" (click)="link()" [nzLoading]="isConfirmLoading">Save Changes</button>
  </div>
</nz-modal>


<nz-modal [(nzVisible)]="isVisibleCopyLink" nzTitle="Meet" [nzWidth]="500" (nzOnCancel)="handleCancelCopyLink()"
  (nzOnOk)="link()">
  <ng-container *nzModalContent>

    <div class="container">
      <div class="row justify-content-center">
        <div class="col-10">
          <div class="pb-2">Please copy & share the below link with others so that they can join the meeting.</div>
          <div class="input-group">
            <input type="text" class="form-control" [(ngModel)]="text1"
              placeholder="content will be copy after click the copy button" disabled />
            <span class="input-group-append">
              <button style="height: 34px;" nzTooltipTrigger="click" nzTooltipTitle="copied!" nzTooltipPlacement="top"
                nz-button nz-tooltip class="btn btn-default" type="button" ngxClipboard [cbContent]="text1"
                (cbOnSuccess)="isCopied1 = true" (cbOnError)="onCopyFailure()" [class.btn-success]="isCopied1">
                <i nz-icon nzType="copy" nzTheme="outline"></i>
              </button>
            </span>
          </div>
          <a nz-button nzType="link" class="pt-2" (click)="shareWithMessages()"><i class="fe fe-mail mr-1"></i>Share
            with messages</a>
          <a nz-button nzType="link" class="pt-2" (click)="shareWithChat()"><i
              class="fe fe-message-square mr-1"></i>Share with chat</a>
        </div>
      </div>
    </div>


  </ng-container>
  <div *nzModalFooter>
    <button nz-button nzType="default" (click)="handleCancelCopyLink()">Close</button>
    <button nz-button nzType="primary" (click)="handleCancelCopyLink()" [nzLoading]="isConfirmLoading">Ok</button>
  </div>
</nz-modal>

<nz-modal [nzStyle]="{ top: '20px' }" [nzWidth]="'80%'" [(nzVisible)]="isVisibleTop" nzTitle="Global Search..."
  [nzFooter]="modalFooter" (nzOnCancel)="handleOkTop()">
  <ng-container *nzModalContent>
    <div class="ml-2 mr-4 d-none d-md-block">
      <form class="example-form">
        <mat-form-field class="example-full-width mat-width">
          <mat-label>Seach anything...</mat-label>
          <input type="text" placeholder="Enter atlest 3 letters" matInput (input)="onInputChange($event.target.value)"
            [formControl]="myControl">
        </mat-form-field>
      </form>

      <div style="max-height: 400px; overflow-y: auto;">

        <div class="module-header" *ngIf="complianceSearchResults.length > 0">Compliance</div>
        <a *ngFor="let result of complianceSearchResults" [routerLink]="['/user/compliance/doc/']"
          [queryParams]="{ docId: result.document_id._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file-pdf" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.document_id.name }}</span>
            <span class="module-name">Compliance</span>
          </mat-option>
        </a>


        <div class="module-header" *ngIf="docsVaultOwnSearchResults.length > 0 || docsVaultaccessibleSearchResults.length > 0 || 
          docsVaultFoldersSearchResults.length > 0">DocsVault</div>
        <a *ngFor="let result of docsVaultOwnSearchResults" [routerLink]="['/user/doc_lib/doc/']"
          [queryParams]="{ docId: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">DocsVault</span>
          </mat-option>
        </a>
        <a *ngFor="let result of docsVaultaccessibleSearchResults" [routerLink]="['/user/doc_lib/doc/']"
          [queryParams]="{ docId: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">DocsVault</span>
          </mat-option>
        </a>
        <a *ngFor="let result of docsVaultFoldersSearchResults" [routerLink]="['/user/doc_lib/folder/']"
          [queryParams]="{ folderId: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="folder" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">DocsVault</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="checklistSearchResults.length > 0 || taskSearchResults.length > 0">TrackTask
        </div>
        <a *ngFor="let result of checklistSearchResults" [routerLink]="['/user/checklist/task-dashboard/']"
          [queryParams]="{ id: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="carry-out" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">Checklist</span>
          </mat-option>
        </a>
        <a *ngFor="let result of taskSearchResults" [routerLink]="['/user/checklist/task-details/']"
          [queryParams]="{ id: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="profile" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.requirementTitle }}</span>
            <span class="module-name">Task</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="driveSearchResults.length > 0 || driveDocumentsSearchResults.length > 0">
          MyDrive</div>
        <a *ngFor="let result of driveSearchResults" [routerLink]="['/user/mydrive/folder/']"
          [queryParams]="{ folderId: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="folder" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">MyDrive</span>
          </mat-option>
        </a>
        <a *ngFor="let result of driveDocumentsSearchResults" (click)="openFile(result.path)">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.name }}</span>
            <span class="module-name">MyDrive</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="productsSearchResults.length > 0">Products</div>
        <a *ngFor="let result of productsSearchResults" [routerLink]="['/user/product-module/product-documents/']"
          [queryParams]="{ id: result.productId._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.productId.productName }}</span>
            <span class="module-name">Products</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="changeCentersSearchResults.length > 0">ChangeCenter</div>
        <a *ngFor="let result of changeCentersSearchResults" [routerLink]="['/user/sic/sic-details/']"
          [queryParams]="{ CustomerSicId: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="file" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.globalSicId.nameAndRefrenceNumberOfModulesWithChanges }}</span>
            <span class="module-name">ChangeCenter</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="inboxSearchResults.length > 0">Inbox</div>
        <a *ngFor="let result of inboxSearchResults" [routerLink]="['/user/request/request-info/']"
          [queryParams]="{ request_id: result.requestId._id, mail_id: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="mail" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.requestId.subject }}</span>
            <span class="module-name">Inbox</span>
          </mat-option>
        </a>

        <div class="module-header" *ngIf="sentboxSearchResults.length > 0">Sentbox</div>
        <a *ngFor="let result of sentboxSearchResults" [routerLink]="['/user/request/request-info/']"
          [queryParams]="{ request_id: result.requestId._id, mail_id: result._id }" target="_blank">
          <mat-option>
            <i class="mr-1" nz-icon nzType="mail" nzTheme="twotone"></i>
            <span class="ellipsis-span">{{ result.requestId.subject }}</span>
            <span class="module-name">Sentbox</span>
          </mat-option>
        </a>
      </div>
    </div>
  </ng-container>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="handleOkTop()">Ok</button>
  </ng-template>
</nz-modal>