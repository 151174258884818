import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
  ViewChild,
} from '@angular/core'
import { Router } from '@angular/router'
import { Location } from '@angular/common'
//import { ViewChild, ElementRef } from '@angular/core'
import { HttpService } from '../../../services/http/http.service'
import { ChatService } from '../../../services/chat/chat.service'

import { NzNotificationService } from 'ng-zorro-antd/notification'
import { SharedService } from '../../../services/shared.service'
import { ConnectionService } from 'ng-connection-service'

import { Observable, Observer, fromEvent, merge } from 'rxjs'
import { map } from 'rxjs/operators'

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef
  @ViewChild(TemplateRef, { static: false }) template?: TemplateRef<{}>

  activeIndex = 0
  chatGroups: any = []
  messages: any = []
  myId: any = false
  container: HTMLElement
  groupId: any = false
  internalUsers: any = []
  notifications: any = []
  selectedId: any = false
  selectedGroup: any = {}
  badgeCount: boolean
  userData
  showDashboard = false
  status = 'ONLINE'
  isConnected = true

  popUpMessage: any = {} //for chat message

  constructor(
    public sharedService: SharedService,
    public services: HttpService,
    public chatService: ChatService,
    private notification: NzNotificationService,
    private router: Router,
    private connectionService: ConnectionService,
    private ViewContainer: ViewContainerRef,
  ) {
    this.userData = JSON.parse(this.services.getItem('userData'))
    if (this.userData) {
      this.userData.role.forEach(role => {
        if (role.userRoleName == 'User') {
          this.showDashboard = true
        }
      })

      this.myId = this.userData.userId
      this.chatService.connectSocketOwn(this.myId)
    }

    // To check online status using ng-connection-service::::

    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected
      if (this.isConnected) {
        this.status = 'ONLINE'
        //  alert(this.status)
        this.myId = this.userData.userId
        this.chatService.connectSocketOwn(this.myId)
        this.chatService.selectedGroup = false
        this.getChatGroups()
      } else {
        this.status = 'OFFLINE'
        // alert(this.status)
        if (this.chatService.selectedGroup) {
          let data = {
            group_id: this.chatService.selectedGroup,
            user_id: this.myId,
          }
          this.chatService.leaveFromRoom(data)
          this.chatService.selectedGroup = false
        }
        this.chatService.disconnectSocket()
      }
    })

    /*
       //Another way to check online status using  rxjs
       this.createOnline$().subscribe((isOnline) => {
         if (isOnline) {
           this.myId = this.userData.userId
           this.chatService.connectSocketOwn(this.myId)
           this.chatService.selectedGroup = false
           this.getChatGroups()
         } else {
           if (this.chatService.selectedGroup) {
             let data = {
               group_id: this.chatService.selectedGroup,
               user_id: this.myId,
             }
             this.chatService.leaveFromRoom(data)
             this.chatService.selectedGroup = false
           }
           this.chatService.disconnectSocket();
         }
       });
       */
  }

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine)
        sub.complete()
      }),
    )
  }

  ngOnInit(): void {
    this.getChatGroups()
    //
    this.chatService.gotNewMessage().subscribe((message: any) => {
      //chat-- receiver msg calling here
      this.chatService.findUserAndShift(message.group_id)

      if (this.chatService.selectedGroup == message.group_id) {
        this.playChatRoomAudio()
      } else {
        this.chatService.unreadNotificationsExist = true
        if (message.msgTextUsersDeleted || message.msgTextUsersAdded) {
          this.getChatGroups()
        } else {
          this.createBasicNotification(message)
          this.playAudio()
        }
      }
    })
  }

  getIusers() {
    // this.spinner.show()
    const request = {
      method: 'GET',
      action_url: 'user/get_internal_users',
    }
    this.services.doHttp(request).subscribe(
      res => {
        if ('data' in res) {
          this.internalUsers = res['data']
        }
      },
      error => {
        // this.notification.notify('error', 'internal server error')
      },
    )
  }

  getChatGroups() {
    const request = {
      method: 'GET',
      action_url: 'chat/all_chat_groups',
    }
    this.services.doHttp(request).subscribe(
      res => {
        if ('data' in res) {
          this.chatService.chatGroups = res['data']
          this.chatService.chatGroups.forEach(each => {
            if (each.unreadCount) {
              this.chatService.unreadNotificationsExist = true
              this.chatService.chatUnreadCount = this.chatService.chatUnreadCount + each.unreadCount
            }
          })
        }
      },
      error => {
        // this.notification.create('error', 'Failed', error.error.msg, {
        //   nzPlacement: 'bottomRight',
        // })
      },
    )
  }

  selectUser(user, index) {
    this.router.navigate(['/user/chat'])
  }

  //test p
  playAudio() {
    //user out of chat room
    let audio = new Audio()
    audio.src =
      'https://res.cloudinary.com/moxiedb/video/upload/v1643039365/Notificaion%20tones/not-bad-549_wr0jxn.mp3'
    audio.load()
    audio.play().then(() => {})
  }

  createBasicNotification(message): void {
    this.popUpMessage = message
    this.popUpMessage.msg = `${this.popUpMessage.msg.substring(0, 20)}..`
    if (this.popUpMessage.designation == 'Group') {
      this.popUpMessage.groupName = this.popUpMessage.group_name
    }
    document.getElementById('popup-btn').click()
  }

  createBasicNotificationTemplate(template: TemplateRef<{}>): void {
    this.notification.template(template, {
      nzStyle: {
        width: '270px',
        height: '100px',
        // marginLeft: '-265px',
      },
      nzClass: 'test-class',
      nzDuration: 2200,
    })
  }

  playChatRoomAudio() {
    let audio = new Audio()
    audio.src =
      'https://res.cloudinary.com/moxiedb/video/upload/v1643039381/Notificaion%20tones/definite-555_nu6ixx.mp3'
    audio.load()
    audio.play().then(() => {})
  }
}
