import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Router } from '@angular/router'
import { Observable } from 'rxjs/index'
import { NotifierService } from 'angular-notifier'
import { userEnvironment } from '../../../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public token: any = false
  public userData: any = false
  public playingRole: any = false
  public menuData: any = []

  // for idle track
  timoutWarning: any = 540000
  timoutNow: any = 60000
  warningTimer: any
  timeoutTimer: any
  ResetTimeOutEnable: any = false
  lastApiCalled: any = 0

  public validations = {
    email: /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i,
    otp: /^\d{6}$/,
  }
  public baseUrl: any = '/api/apiv1/'
  openSessionExpiry: any = false
  sessionExpiryIn: any = 60
  warningTime: any = 0
  warningTimeInterval: any
  sessionExpiryInterval: any

  constructor(
    private http: HttpClient,
    private notification: NotifierService,
    private router: Router,
  ) {
    // window.addEventListener('beforeunload', () => {
    //   this.logout()
    // })
    this.verifyExistingToken()
    setInterval(
      function() {
        this.lastApiCalled++
      }.bind(this),
      1000,
    )

    document.addEventListener(
      'mousemove',
      function() {
        if (this.ResetTimeOutEnable) {
          this.setItem('lastActivity', new Date())
        }
        this.timoutWarning = 540000
        this.ResetTimeOutTimer()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'mousedown',
      function() {
        if (this.ResetTimeOutEnable) {
          this.setItem('lastActivity', new Date())
        }
        this.timoutWarning = 540000
        this.ResetTimeOutTimer()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'keypress',
      function() {
        if (this.ResetTimeOutEnable) {
          this.setItem('lastActivity', new Date())
        }
        this.timoutWarning = 540000
        this.ResetTimeOutTimer()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'touchmove',
      function() {
        if (this.ResetTimeOutEnable) {
          this.setItem('lastActivity', new Date())
        }
        this.timoutWarning = 540000
        this.ResetTimeOutTimer()
      }.bind(this),
      false,
    )
    document.addEventListener(
      'onscroll',
      function() {
        if (this.ResetTimeOutEnable) {
          this.setItem('lastActivity', new Date())
        }
        this.timoutWarning = 540000
        this.ResetTimeOutTimer()
      }.bind(this),
      false,
    )
  }

  public doHttp(request) {
    this.lastApiCalled = 0
    this.timoutWarning = 540000
    this.ResetTimeOutTimer()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, JSON.stringify(request.params), {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  public doHttp1(request) {
    this.lastApiCalled = 0
    this.timoutWarning = 540000
    this.ResetTimeOutTimer()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }

    if (request.method === 'GET') {
      const params = ''
      return this.http.get(this.baseUrl + request.action_url + params, {
        headers: options,
      })
    } else if (request.method === 'POST') {
      return this.http.post(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'PUT') {
      return this.http.put(this.baseUrl + request.action_url, request.params, {
        headers: options,
      })
    } else if (request.method === 'DELETE') {
      return this.http.delete(this.baseUrl + request.action_url, {
        headers: options,
      })
    }
  }

  verifyExistingToken() {
    const existingToken = localStorage.getItem('tokenID')
    if (existingToken) {
      const request = {
        method: 'GET',
        action_url: 'user/check_existing_token?t=' + existingToken,
      }
      this.doHttp(request).subscribe(
        (res: any) => {
          if (res.status) {
            this.token = localStorage.getItem('tokenID')
            const menudata = localStorage.getItem('menuData')
            if (this.token) {
              // console.log('enable timer')
              this.ResetTimeOutEnable = true
              this.ResetTimeOutTimer()
              if (localStorage.getItem('userData')) {
                this.userData = JSON.parse(localStorage.getItem('userData'))
              }

              this.playingRole = localStorage.getItem('playingRole')
              if (menudata) {
                this.menuData = JSON.parse(menudata)
              }
            }
          } else {
            this.ResetTimeOutEnable = false
            this.resetWarningTimer()
            this.token = false
            this.userData = false
            this.removeItem('tokenID')
            this.removeItem('userData')
            this.router.navigate(['/auth/login'])
          }
        },
        error => {},
      )
    }
  }

  public activeTrigger() {
    this.lastApiCalled = 0
    this.ResetTimeOutTimer()
    let options
    if (this.token) {
      options = new HttpHeaders()
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
        .set('pragma', 'no-cache')
        .set('Authorization', `Bearer ${this.token}`)
        .set('Role', `${this.playingRole}`)
    } else {
      options = new HttpHeaders()
        .set('pragma', 'no-cache')
        .set('Access-Control-Allow-Origin', '*')
        .set('cache-control', 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0')
    }
    return this.http.post(
      this.baseUrl + 'user/active_trigger',
      {},
      {
        headers: options,
      },
    )
  }

  /**
   * store user data
   * @param data
   */
  public storeUserData(data) {
    this.token = data['tokenID']
    this.setItem('token', data['tokenID'])
  }

  /* set key value from local storage */

  public setItem(key, item) {
    window.localStorage.setItem(key, item)
  }

  /* get key value from local storage */

  public getItem(key) {
    const temp = window.localStorage.getItem(key)
    return temp
  }

  public removeItem(key) {
    window.localStorage.removeItem(key)
  }

  public errorMsg(error) {
    if (error.status === 401) {
      if (error.error.auth_declined) {
      } else {
        this.logout()
      }
    }
  }

  success_notification(msg) {
    this.notification.notify('success', msg)
  }

  error_notification(msg) {
    this.notification.notify('error', msg)
  }

  public logout() {
    const request = {
      method: 'GET',
      action_url: 'user/logout?token=' + this.token,
    }
    this.doHttp1(request).subscribe(
      (res: any) => {
        if (res.status) {
          this.token = false
          this.userData = false
          this.removeItem('tokenID')
          this.removeItem('userData')
          this.router.navigate(['/auth/login'])
        }
      },
      error => {},
    )
  }

  public sliceData(msg) {
    if (msg) {
      const msges = msg.split('\n\n')
      if (msges.length > 0) {
        if (msges.length == 1) {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          }
        } else {
          if (msges[0].length > 35) {
            return msges[0].substring(0, 35)
          } else {
            if (msges[1].length > 35) {
              return msges[0] + msges[1].substring(0, 35)
            } else {
              return msges[0] + msges[1]
            }
          }
        }
      } else {
        return msg
      }
    } else {
      return msg
    }
  }

  StartWarningTimer() {
    // console.log('warning started')
    this.warningTimer = setTimeout(
      function() {
        this.activityCheck()
      }.bind(this),
      this.timoutWarning,
    )

    this.warningTimeInterval = setInterval(
      function() {
        this.warningTime++
        // console.log('warning time', this.warningTime)
      }.bind(this),
      1000,
    )
  }

  resetWarningTimer() {
    clearTimeout(this.warningTimer)
  }

  ResetTimeOutTimer() {
    // console.log(this.ResetTimeOutEnable)

    if (this.ResetTimeOutEnable) {
      this.ResetTimeOutEnable = false
      // console.log('timer started')
      this.warningTime = 0
      clearTimeout(this.warningTimer)
      clearTimeout(this.timeoutTimer)
      clearInterval(this.sessionExpiryInterval)
      clearInterval(this.warningTimeInterval)
      this.StartWarningTimer()

      setTimeout(
        function() {
          this.ResetTimeOutEnable = true
        }.bind(this),
        1000,
      )
      if (this.lastApiCalled > 60) {
        this.activeTrigger().subscribe(res => {})
      }
    }
  }

  IdleWarning() {
    this.sessionExpiryIn = 60
    this.sessionExpiryInterval = setInterval(
      function() {
        this.sessionExpiryIn--
      }.bind(this),
      1000,
    )
    this.openSessionExpiry = true
    this.ResetTimeOutEnable = false
    // console.log('warning cleared and timeout started')
    clearTimeout(this.warningTimer)
    this.timeoutTimer = setTimeout(
      function() {
        this.IdleTimeout()
      }.bind(this),
      this.timoutNow,
    )
  }

  public activityCheck() {
    const date = new Date()
    date.setTime(date.getTime() - 540000)
    if (this.getItem('lastActivity')) {
      const lastActivity = new Date(this.getItem('lastActivity'))
      if (lastActivity > date) {
        const dateNow = new Date()
        const timediff = dateNow.getTime() - lastActivity.getTime()
        this.timoutWarning = 540000 - timediff
        // console.log(this.timoutWarning)
        this.ResetTimeOutEnable = true
        this.ResetTimeOutTimer()
        this.openSessionExpiry = false
      } else {
        this.IdleWarning()
      }
    }
  }

  IdleTimeout() {
    clearInterval(this.sessionExpiryInterval)
    this.logout()
  }
}
